import React from "react";
import "../css/InfoPage.css";

const InfoPage = (props) => {
  return (
    <section className="info-page-wrapper">
      <div className="info-page-container">
        <div className="info-block">
          <div className="info-section">
            <div className="section-text">
              <h1>{props.section1Header}</h1>
              <p>{props.section1Text}</p>
            </div>
          </div>
          <div className="image-div">
            <img className="section-image" src={props.section1Image} alt={props.section1Alt} />
          </div>
        </div>
        <div className="info-block">
          <div className="image-div">
            <img className="section-image" src={props.section2Image} alt={props.section2Alt} />
          </div>
          <div className="info-section">
            <div className="section-text">
              <h2>{props.section2Header}</h2>
              <p>{props.section2Text}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoPage;
