import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import * as Index from "../components/images/index.js";

export default function TeamPage() {
    return (
        <main>
            <Navbar />
            <InfoPage
                section1Header="Professional Bouncers"
                section1Text={`With over 70+ years of combined experience coaching both recreational and competitive Trampoline, SGA prides itself on its top-quality Trampoline and Power Tumbling programs.
        Our facility uses continuously updated training regimens designed specifically to develop balance, flexibility, agility, cardio, spatial as well as kinesthetic awareness, proprioception and most importantly, goal setting and actualization.`}
                section1Image={Index.Walltramp1}
                section1Alt="Wall Trampoline"
                section2Header="Our Program"
                section2Text={`The trampoline program at Shasta Gymnastics Academy (SGA) in addition to utilizing high-quality equipment and developing fitness and life skills, also focuses on creating a fun and positive training environment. 
        We understand that gymnastics development is a long-term process that can be demanding and repetitive, so our coaches work hard to bring excitement and passion to each and every class. Whether you are looking to join a recreational trampoline program or compete at a higher level, SGA has something to offer for every athlete. 
		Come in and see for yourself why our trampoline program is the best in the area.`}
                section2Image={Index.MalikHiFive}
                section2Alt="Trampoline Hi Five"
            />
            <Footer />
        </main>
    );
}
