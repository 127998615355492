import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage.jsx";
import AboutPage from "./Pages/AboutPage.jsx";
import CalendarPage from "./Pages/CalendarPage.jsx";
import Missing from "./Pages/Missing.jsx";
import TeamPage from "./Pages/TeamPage";
import GalleryPage from "./Pages/GalleryPage";
import SharePage from "./Pages/SharePage";
import ContactPage from "./Pages/ContactPage";
import Trampoline from "./Pages/TrampolinePage";
import TrampWall from "./Pages/TrampWallPage";
import Kinder from "./Pages/KinderPage";
import Facility from "./Pages/FacilityPage";
import Classes from "./Pages/ClassesPage";
import Events from "./Pages/EventsPage";
import GirlsGymnastics from "./Pages/GirlsGymnasticsPage";
import Birthday from "./Pages/BirthdayPage";

function App() {
  return (
    <Routes>
      <Route sensitive={false} path="/" element={<Homepage />} />
      <Route sensitive={false} path="/home" element={<Homepage />} />
      <Route sensitive={false} path="/calendar" element={<CalendarPage />} />
      <Route sensitive={false} path="/about" element={<AboutPage />} />
      <Route sensitive={false} path="/team" element={<TeamPage />} />
      <Route sensitive={false} path="/contact" element={<ContactPage />} />
      <Route sensitive={false} path="/gallery" element={<GalleryPage />} />
      <Route sensitive={false} path="/share" element={<SharePage />} />
      <Route sensitive={false} path="/trampoline" element={<Trampoline />} />
      <Route sensitive={false} path="/kinder" element={<Kinder />} />
      <Route sensitive={false} path="/events" element={<Events />} />
      <Route sensitive={false} path="/classes" element={<Classes />} />
      <Route sensitive={false} path="/facility" element={<Facility />} />
      <Route sensitive={false} path="/birthday" element={<Birthday />} />
      <Route sensitive={false} path="/gymnastics" element={<GirlsGymnastics />} />
      <Route sensitive={false} path="/trampwall" element={<TrampWall />} />
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
