import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import * as Index from "../components/images/index.js";

export default function ClassesPage() {
  return (
    <main>
      <Navbar />
      <InfoPage
        section1Header="Birthday Parties"
        section1Text={`
Call to book your party today! (530)-229-9194
Celebrate your birthday with Shasta Gymnastics Academy in Redding, CA! Our birthday parties are a fun and exciting way to celebrate your special day. With a variety of activities to choose from, including gymnastics, trampoline, and games, your party is sure to be a hit! Our experienced staff will lead the activities and take care of all the details, so you can relax and enjoy the party. Available on unbooked weekends Saturday/Sunday, Contact us today to book your party!`}
        section1Image={Index.PaintedFloor}
        section1Alt=""
        section2Header="Private Events"
        section2Text={`SGA offers a variety of private events, including private trainings, gym reservations for non-birthday events, 1-on-1 gymnastics lessons, and 1-on-1 fitness trainings. Our experienced staff will work with you to create a customized event that meets your needs and goals. Whether you are looking to improve your gymnastics skills, get in shape, or just have fun, we can work with you to meet your needs. Contact us today to learn more!`}
        section2Image={Index.Doors}
        section2Alt="Pike Jump"
      />
      <Footer />
    </main>
  );
}
