export const Kinder1 = require("./Kinder1.webp");
export const BeamsLogo = require("./P1070737.webp");
export const LogoBanner = require("./P1070738.webp");
export const LogoSign = require("./ShastaGymRealLogo.webp");
export const LogoFill = require("./Shasta_Logo_Circle.png");
export const Trampolines = require("./P1070740.webp");
export const Walltramp1 = require("./WallTramp1.webp");
export const MalikHiFive = require("./Malik-hifive.webp");
export const FlyingKick = require("./Luca_Tramp_I.png");
export const NoahTrampwall1 = require("./Noah_TrampWall_I.png");
export const StraddleJumpBlur = require("./Ethan_Tramp_I.png");
export const KinderBars1 = require("./P1080186.jpg");
export const CompBars1 = require("./P1080184.jpg");
export const FloorBeams1 = require("./P1080178.jpg");
export const Kinder2 = require("./P1080192.jpg");
export const RodFloorPerspective = require("./P1080174.jpg");
export const DoubleMini1 = require("./P1080172.jpg");
export const Trampoline1 = require("./P1080167.jpg");
export const FullGym1 = require("./P1080161.jpg");
export const StraddleJump1 = require("./P1080113.jpg");
export const StraddleJump2 = require("./P1080125.jpg");
export const StraddleJump3 = require("./P1080125.jpg");
export const FunkyPikeJump = require("./P1080105.jpg");
export const FlyingKick2 = require("./P1080093.jpg");
export const PaintedFloor = require("./P1080212.webp");
export const Doors = require("./P1080215.webp");
