import React from "react";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";
import { Link } from "react-router-dom";
import { SidebarData } from "./ComponentData/SidebarData.js";
import "../css/Navbar.css";

import Logo from "../images/Shasta_Logo_Circle.png";

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebar: false,
            profileMenu: false,
            isHovering: false,
        };

        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver() {
        this.setState({ isHovering: true });
    }

    handleMouseOut() {
        this.setState({ isHovering: false });
    }

    showSidebar() {
        this.setState({ sidebar: !this.state.sidebar, profileMenu: false });
    }

    render() {
        return (
            <div>
                <div className="navbar">
                    <Link
                        to="#"
                        aria-label="Open Sidebar Button"
                        className="menu-bars"
                    >
                        <IoIcons.IoMdMenu onClick={() => this.showSidebar()} />
                    </Link>
                    <Link
                        to="/home"
                        aria-label="Home Button"
                        className="logo-link-container"
                    >
                        <img
                            alt="shasta logo"
                            src={Logo}
                            className="navbar-logo"
                            width={65}
                            height={65}
                        ></img>
                    </Link>
                    <div className="navbar-right">
                        <Link to="/calendar" className="header-link">
                            <button className="header-button">
                                Class Schedule
                            </button>
                        </Link>
                        <Link to="/about" className="header-link">
                            <button className="header-button">About Us</button>
                        </Link>
                        <Link to="/contact" className="header-link">
                            <button className="header-button">
                                Contact Us
                            </button>
                        </Link>
                        <div className="parent-portal-buttons">
                            <a
                                href="https://app.jackrabbitclass.com/regv2.asp?id=532938"
                                className="header-link nomargin"
                            >
                                {this.state.isHovering && (
                                    <button
                                        aria-label="register for parent portal"
                                        type="button"
                                        className="parent-portal-register-button"
                                    >
                                        <span
                                            role="button"
                                            onMouseOver={() =>
                                                this.handleMouseOver()
                                            }
                                            onMouseOut={() =>
                                                this.handleMouseOut()
                                            }
                                            className="reg-button-front"
                                        >
                                            Register
                                        </span>
                                    </button>
                                )}
                                {!this.state.isHovering && (
                                    <button
                                        aria-label="parent portal buttons, register and login buttons on hover"
                                        type="button"
                                        className="parent-portal-button"
                                    >
                                        <span
                                            role="button"
                                            onMouseOver={() =>
                                                this.handleMouseOver()
                                            }
                                            onMouseOut={() =>
                                                this.handleMouseOut()
                                            }
                                            className="button-front"
                                        >
                                            Parent Portal
                                        </span>
                                    </button>
                                )}
                            </a>
                            <a
                                href="https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=532938"
                                className="header-link nomargin"
                            >
                                <button
                                    aria-label="login to parent portal"
                                    type="button"
                                    className="parent-portal-login-button"
                                >
                                    {this.state.isHovering && (
                                        <span
                                            role="button"
                                            onMouseOver={() =>
                                                this.handleMouseOver()
                                            }
                                            onMouseOut={() =>
                                                this.handleMouseOut()
                                            }
                                            className="login-button-front"
                                        >
                                            Login
                                        </span>
                                    )}
                                </button>
                            </a>
                        </div>
                        <Link
                            to="/home"
                            className="home-icon"
                            aria-label="Home Icon"
                        >
                            <FaIcons.FaHome />
                        </Link>
                    </div>
                </div>
                <nav
                    className={
                        this.state.sidebar ? "nav-menu active" : "nav-menu"
                    }
                >
                    <ul
                        className="nav-menu-items"
                        onClick={() => this.showSidebar()}
                    >
                        <li className="navbar-toggle">
                            <Link
                                to="#"
                                className="menu-bars"
                                aria-label="Close Sidebar Button"
                            >
                                <IoIcons.IoMdClose />
                            </Link>
                        </li>
                        {SidebarData.map((item, index) => {
                            return (
                                <li key={index} className={item.cName}>
                                    {item.path ? (
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span className="sidebar-item-title">
                                                {item.title}
                                            </span>
                                        </Link>
                                    ) : (
                                        <a href={item.href}>
                                            {item.icon}
                                            <span className="sidebar-item-title">
                                                {item.title}
                                            </span>
                                        </a>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
        );
    }
}
