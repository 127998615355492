import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import MapSection from "../components/Map";

const location = {
	address: "2629 Bechelli Ln, Redding, CA 96002",
	lat: 40.56978,
	lng: -122.36169,
};

export default function Homepage() {
	return (
		<main role="main">
			<Navbar />
			<Contact />
			<MapSection location={location} zoomLevel={18} />
			<Footer />
		</main>
	);
}
