import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import * as Index from "../components/images/index.js";

export default function ClassesPage() {
    return (
        <main>
            <Navbar />
            <InfoPage
                section1Header="Special Events "
                section1Text={`Did you know SGA offers all kinds of Special and Private events?\n
		Birthday Parties\n
		Open Trainings\n
		1-on-1 Gymnastics Lessons\n
		1-on-1 Fitness Trainings\n
		Give us a call to learn more!`}
                section1Image={Index.FullGym1}
                section1Alt="Gymnastics Gym"
                section2Header="Exhibition Team!"
                section2Text={`Interested in showing off your skills? Join our Exhibition Team! With weekly practices and regular events and showcases to perform your favorite skills for you friends, family, and the community. Exhibition team is a great way to improve your trampoline and performing skills without the pressure of competition.`}
                section2Image={Index.FunkyPikeJump}
                section2Alt="Pike Jump"
            />
            <Footer />
        </main>
    );
}
