import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { AiFillMail } from "react-icons/ai";
import { IoMdPin } from "react-icons/io";

import * as Index from "../components/images/index.js";

import "../css/About.css";

export default function ClassesPage() {
  return (
    <main>
      <Navbar />
      <section className="info-page-wrapper">
        <div className="info-page-container">
          <div className="info-block">
            <div className="info-section">
              <div className="section-text">
                <h1>Ages 4 - 18</h1>
                <p
                  className="homepage-info-p text-blue"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  <a className="homepage-info-a" href="https://shastagymnastics.com/calendar">
                    Class Schedule
                  </a>
                  <br />
                  For more info, to sign up for classes, scheduling birthday parties, or scheduling
                  make-ups come on in or give us a call at:&nbsp;
                  <a className="homepage-info-a" href="tel:5302299194">
                    (530)-229-9194
                  </a>
                </p>
                <p>
                  From your first day in kindergarten to your first day as an adult, Shasta
                  Gymnastics has classes for every age in between. For kids ages 4 to 18, Shasta
                  Gymnastics Academy (SGA) provides a range of classes, including Kinder Gym,
                  Trampoline, Tumbling, Trampoline Team, and Girls Gymnastics. These courses can
                  help kids in any other athletic endeavors they might pursue by enhancing their
                  athletic ability, fitness and coordination. SGA is dedicated to giving kids a
                  positive and secure atmosphere where they can grow in agility, coordination,
                  strength, and flexibility while laying the groundwork for creating and achieving
                  goals. A training program that emphasizes all essential elements of athlete
                  development while keeping a happy and supportive environment has been developed by
                  the skilled staff of SGA, who have a decades of direct gymnastics experience as
                  coaches and competitors.
                </p>
                <div>
                  <p>
                    <AiFillMail className="info-icon" />
                    <a
                      href="mailto: shastagymnasticsmedia@gmail.com, info@shastagymnastics.com"
                      className="homepage-info-a"
                    >
                      Email Us
                    </a>
                  </p>
                  <p>
                    <IoMdPin className="info-icon" />
                    <a href="https://goo.gl/maps/DVMGa1QsRyH5BLgNA" className="homepage-info-a">
                      2629 Bechelli Ln, Redding, CA 96002
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="image-div">
              <img className="section-image" src={Index.Kinder1} alt={"Trampoline"} />
            </div>
          </div>
          <div className="info-block">
            <div className="image-div">
              <img className="section-image" src={Index.FlyingKick2} alt={"Flying Kick"} />
            </div>
            <div className="info-section">
              <div className="section-text">
                <h1>A Flippin’ Great Time + New Classes!</h1>
                <p>
                  If you can’t have a good time, why do it? Gymnastics development is a long-term
                  process that can oftentimes feel demanding and repetitive. Which is why our
                  coaches work so hard to bring fun, excitement and passion to our classes each and
                  every week. Keep a lookout for new classes each month! Shasta Gymnastics has a
                  passion for finding innovative new ways to move. Like our brand new Tramp-wall
                  program starting soon! Come on in or give us a call for details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}
