import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import MalikHiFive from "../images/Malik-hifive.webp";

import "../css/About.css";

export default function AboutPage() {
  return (
    <main>
      <Navbar />
      <div className="about-page-wrapper">
        <div className="about-us-container">
          <div className="rectangle-1"></div>
          <div className="rectangle-2"></div>
          <div className="rectangle-3"></div>
          <div className="rectangle-4"></div>
          <h2>What do we do at Shasta?</h2>
          <p className="about-us-p">
            We provide a fun and safe facility where children of all ages can greatly increase their
            athletic skills, benefiting children's strength, balance, and coordination for all other
            sports they may participate in, and teaching basic to advanced trampoline and gymnastics
            skills, in addition to learning necessary life skills.
          </p>
          <hr className="divider" />
          <br />
          <h2>What kinds of classes / sports do you offer?</h2>
          <p className="about-us-p">
            We offer classes in Kinder Gymnastics, Trampoline, Tumbling, Tramp Wall, and Gymnastics
            for kids aged 3-17.
          </p>
          <hr className="divider" />
          <br />
          <h2>What are the benefits of Gymnastics / Trampoline?</h2>
          <p className="about-us-p">
            In our sport, we create strong bodies and healthy minds through continuous development
            of strength, flexibility, coordination, and agility all while building a foundation to
            creating and actualizing goals. Working through failure, developing discipline and
            resilience, and facing fear as just a few examples. Develop skills that will improve
            performance and agility for almost any sport, learn to use coordination and arial
            control that will improve acrobatics used in sports from snowboarding and diving, to
            cheer and circus performance.
          </p>
          <hr className="divider" />
          <br />
          <h2>How does our staff achieve these goals?</h2>
          <p className="about-us-p">
            With decades of combined direct gymnastics experience both coaching and competing, we
            have worked with thousands of kids across the nation and designed a training program
            that teaches all of the necessary components of developing athletes while retaining
            positivity and encouragement to build excitement and passion for the sport. This has
            been our key to creating meaningful and lasting results. Shasta Gymnastics Academy is
            dedicated to serving children and families of Redding.
          </p>
          <hr className="divider" />
          <br />
          <h2>Why should you choose Shasta?</h2>
          <p className="about-us-p">
            We know what it's like to train in toxic and unhealthy environments. We also know what
            kind of long term impact encouraging and positive coaching can have on the developing
            young mind. These key understandings allow us to soar above our competition in creating
            a truly safe space for children to love what they do while doing what they love.
          </p>
          <hr className="divider" />
        </div>
        <h2 className="text-center" style={{ fontSize: "32px" }}>
          Our Staff:
        </h2>
        <div className="bio-container">
          <img src={MalikHiFive} alt="Malik Hi-Five" className="bio-img" />
          <h2>Malik DePasquale</h2>
          <h3>
            Co-Owner
            <br />
            Head Trampoline + Tumbling Coach
          </h3>
          <br />
          <h3>Athlete Experience:</h3>
          <p className="bio-p">
            I was a competitive Artistic Gymnast for 12 years. Starting my gymnastics career at the
            fresh age of 7 in Northern Nevada. At age 15 I moved to North Carolina with a host
            family where I would go on to compete as a Level 10 (the highest level before
            elite/international level), be selected as the 2013 North Carolina athlete of the year,
            and become the 2013 National Champion on High Bar. Two years later I moved back to
            Northern Nevada and began my 5 year career as an Elite Trampolinist, winning a total of
            6 National Titles in Double-Mini, Trampoline and Synchronized Trampoline.
          </p>
          <br />
          <h3>Coaching Experience:</h3>
          <p className="bio-p">
            In 2014 I took over as head coach for Tumbleweeds Gymnastics' competitive Trampoline &
            Tumbling program in Northern Nevada. Over the course of 9 beautiful years, I built a
            family of empowering individuals and athletes, ten of which reached level 10 and three
            reaching the elite levels. After a short stint at Technique Gymnastics in Sacramento, I
            was presented with the opportunity of becoming the proud new owner of your very own
            Shasta Gymnastics Academy.
          </p>
          <hr className="divider" />
          <p className="bio-p">
            Gymnastics has been pivotal in shaping me and pushing me to continue growing throughout
            my life. It's taught me patience, strengthened me physically and emotionally, and most
            importantly, it gave me a safe place to make mistakes and learn from each and every one
            of them. I've now made it my goal to pass these necessary life skills on to a new
            generation of athletes... And likely with some improved "Technique's".
          </p>
          <p className="bio-p">
            I believe every child deserves to live a life that they love. To wake up with excitement
            and a blazing passion to experience the cards they were dealt. And I believe that a
            healthy body and a strong mind are the two most necessary components to creating that
            life.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
}
