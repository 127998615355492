import React from "react";

import * as Images from "../images/index";

export const imagesData = [
    {
        header: "Shasta Gymnastics",
        img: (
            <img
                alt="Gymnastics Gym"
                className="gallery-image"
                src={Images.FullGym1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Kinder Gym",
        img: (
            <img
                alt="Gymnastics Gym"
                className="gallery-image"
                src={Images.Kinder1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Tramp Wall",
        img: (
            <img
                alt="Trampoline Wall"
                className="gallery-image"
                src={Images.Walltramp1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Olympic Trampolines",
        img: (
            <img
                alt="Trampolines"
                loading="lazy"
                className="gallery-image"
                src={Images.Trampolines}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Womens Artistic Gymnastics",
        img: (
            <img
                alt="Balance Beams"
                loading="lazy"
                className="gallery-image"
                src={Images.BeamsLogo}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "High Flying Fun",
        img: (
            <img
                alt="Trampoline Hi Five"
                loading="lazy"
                className="gallery-image"
                src={Images.MalikHiFive}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Massive Air!",
        img: (
            <img
                alt="Flying Kick"
                loading="lazy"
                className="gallery-image"
                src={Images.FlyingKick}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Trampoline",
        img: (
            <img
                alt="Trampoline"
                loading="lazy"
                className="gallery-image"
                src={Images.Trampoline1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Flying Kick!",
        img: (
            <img
                alt="Flying Kick"
                loading="lazy"
                className="gallery-image"
                src={Images.FlyingKick2}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Double Mini Trampoline",
        img: (
            <img
                alt="Double Mini Trampoline"
                loading="lazy"
                className="gallery-image"
                src={Images.DoubleMini1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Tumbling Rod Floor",
        img: (
            <img
                alt="Rod Floor"
                loading="lazy"
                className="gallery-image"
                src={Images.RodFloorPerspective}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Balance Beam",
        img: (
            <img
                alt="Balance Beams"
                loading="lazy"
                className="gallery-image"
                src={Images.FloorBeams1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Uneven Bars",
        img: (
            <img
                alt="Uneven Bars"
                loading="lazy"
                className="gallery-image"
                src={Images.CompBars1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Straddle Jump",
        img: (
            <img
                alt="Straddle Jump"
                loading="lazy"
                className="gallery-image"
                src={Images.StraddleJump1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Kinder Bars",
        img: (
            <img
                alt="Kinder Bars"
                loading="lazy"
                className="gallery-image"
                src={Images.KinderBars1}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Kinder Gym",
        img: (
            <img
                alt="Kinder Gymnastics"
                loading="lazy"
                className="gallery-image"
                src={Images.Kinder2}
            ></img>
        ),
        cName: "pics",
    },
    {
        header: "Straddle Jump",
        img: (
            <img
                alt="Straddle Jump"
                loading="lazy"
                className="gallery-image"
                src={Images.StraddleJump2}
            ></img>
        ),
        cName: "pics",
    },
];
