import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import logoImg from "../images/Shasta_Gymnastics.png";

export default function TeamPage() {
  return (
    <main>
      <Navbar />
      <InfoPage
        section1Header="KINDER"
        section1Text="wowowowowoowowo this is so cooool"
        section1Image={logoImg}
        section1Alt="Logo"
        section2Header="DOG"
        section2Text="Doggy"
        section2Image="https://post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/02/322868_1100-800x825.jpg"
        section2Alt="Doggy"
      />
      <Footer />
    </main>
  );
}
