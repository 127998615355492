import React from "react";

import * as Images from "../images/index";

export const CardData = [
  {
    date: "Shasta Gymnastics",
    header: "Birthday Parties",
    img: (
      <img
        alt="Shasta Gymnastics Floor"
        loading="lazy"
        className="card-image"
        src={Images.PaintedFloor}
      ></img>
    ),
    cName: "card",
    path: "/birthday",
  },
  {
    date: "Shasta Gymnastics",
    header: "Professional Bouncers",
    img: (
      <img
        alt="Trampoline Hi Five"
        loading="lazy"
        className="card-image"
        src={Images.MalikHiFive}
      ></img>
    ),
    cName: "card",
    path: "/trampoline",
  },
  {
    date: "Shasta Gymnastics",
    header: "What are the benefits of Gymnastics / Trampoline?",
    img: <img alt="Trampolines" loading="lazy" className="card-image" src={Images.FullGym1}></img>,
    cName: "card",
    path: "/about",
  },
  {
    date: "Shasta Gymnastics",
    header: "Ages 4 - 18",
    img: <img alt="" loading="lazy" className="card-image" src={Images.StraddleJumpBlur}></img>,
    cName: "card",
    path: "/classes",
  },
  {
    date: "Shasta Gymnastics",
    header: "High Quality Equipment and Facility",
    img: (
      <img alt="Balance Beams" loading="lazy" className="card-image" src={Images.BeamsLogo}></img>
    ),
    cName: "card",
    path: "/facility",
  },
  {
    date: "Shasta Gymnastics",
    header: "New Classes!",
    img: (
      <img alt="Gymnastics Gym" loading="lazy" className="card-image" src={Images.Kinder2}></img>
    ),
    cName: "card",
    path: "/classes",
  },
  {
    date: "Shasta Gymnastics",

    header: "Gymnastics Classes",
    img: <img alt="Trampolines" loading="lazy" className="card-image" src={Images.CompBars1}></img>,
    cName: "card",
    path: "/classes",
  },
  {
    date: "Shasta Gymnastics",

    header: "Olympic Trampolines",
    img: (
      <img alt="Trampolines" loading="lazy" className="card-image" src={Images.Trampolines}></img>
    ),
    cName: "card",
    path: "/facility",
  },
  {
    date: "Shasta Gymnastics",
    header: "Special Events",
    img: (
      <img
        alt="Rod Floor Perspective"
        loading="lazy"
        className="card-image"
        src={Images.RodFloorPerspective}
      ></img>
    ),
    cName: "card",
    path: "/events",
  },
  {
    date: "Shasta Gymnastics",
    header: "A Flippin’ Great Time",
    img: (
      <img
        alt="Trampoline Wall"
        loading="lazy"
        className="card-image"
        src={Images.NoahTrampwall1}
      ></img>
    ),
    cName: "card",
    path: "/classes",
  },
  {
    date: "Shasta Gymnastics",
    header: "Get Your Flip On",
    img: (
      <img alt="Trampolines" loading="lazy" className="card-image" src={Images.Trampoline1}></img>
    ),
    cName: "card",
    path: "/trampoline",
  },
  //   {
  //     date: "January 1st, 2023",
  //     header: "Happy New Year!",
  //     img: (
  //       <img alt="Flying Kick" loading="lazy" className="card-image" src={Images.FlyingKick}></img>
  //     ),
  //     cName: "card",
  //     path: "/trampoline",
  //   },
];
