import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";

import { imagesData } from "../components/ComponentData/GalleryData";

export default function GalleryPage() {
  return (
    <main className="gallery-page-container">
      <Navbar />
      <Gallery Data={imagesData} />
      <Footer />
    </main>
  );
}
