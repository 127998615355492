import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import * as Index from "../components/images/index.js";

export default function TeamPage() {
    return (
        <main>
            <Navbar />
            <InfoPage
                section1Header="Team Program at Shasta Gymnastics Academy"
                section1Text={`The team program at Shasta Gymnastics Academy (SGA) is designed for advanced athletes who are looking to take their skills to the next level. Our team program offers intensive training in all disciplines of gymnastics, including tumbling, trampoline, and artistic gymnastics. 
				Our experienced coaches are dedicated to helping athletes reach their full potential and compete at the highest levels. In addition to regular training, team members have the opportunity to participate in local, regional, and national competitions. We believe that being part of a team fosters a strong sense of camaraderie and helps athletes push themselves to new heights. Our team program is perfect for dedicated and hardworking gymnasts who are looking to take their skills to the next level.`}
                section1Image={Index.Trampolines}
                section1Alt="Logo"
                section2Header="What athletes and parents can expect from the team program"
                section2Text={`The team program at Shasta Gymnastics Academy (SGA) is a commitment for both the athlete and their family. In order to participate in the team program, athletes are expected to consistently attend practices and competitions as scheduled. In addition, team members are expected to maintain a respectable attitude and dedication to their sport while representing their team. We understand that being part of a team can be a significant time commitment, and we appreciate the support of our athletes' families in helping them to achieve their goals.
        		\nWe believe that the team program at SGA offers many benefits for our athletes. In addition to the intensive training and opportunities to compete, being part of a team fosters a sense of belonging and helps athletes to develop important life skills such as discipline, teamwork, time management, and even conflict resolution. We are committed to supporting our team members and helping them to achieve their full potential. We encourage parents to communicate with our coaching staff if they have any questions or concerns about the team program. We are always here to support our athletes and their families.`}
                section2Image={Index.StraddleJump3}
                section2Alt="Logo"
            />
            <Footer />
        </main>
    );
}
