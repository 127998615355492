import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../css/Card.css";

const Cards = ({ Data }) => {
    const scrollRef = React.useRef();
    const [maxScrollLeft, setMaxScrollLeft] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [nextPercent, setNextPercent] = useState(
        (scroll / maxScrollLeft) * 100
    );

    const handleOnScroll = (e) => {
        setScroll(scrollRef.current.scrollLeft);
        setMaxScrollLeft(
            scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
    };

    React.useEffect(() => {
        setMaxScrollLeft(
            scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
    }, []);

    React.useEffect(() => {
        setNextPercent((scroll / maxScrollLeft) * 100);
        for (const image of document.getElementsByClassName("card-image")) {
            image.animate(
                {
                    objectPosition: `${nextPercent}% 50%`,
                    animationTimingFunction: "ease-in-out",
                },
                { duration: 1200, fill: "forwards" }
            );
        }
    }, [scroll, maxScrollLeft, nextPercent]);

    return (
        <>
            <section
                className="card-list"
                id="scrollDemo"
                onScroll={handleOnScroll}
                ref={scrollRef}
            >
                {Data.map((item, index) => {
                    return (
                        <article key={index} className={item.cName}>
                            {item.path ? (
                                <div className="card-interior">
                                    <div>
                                        <header className="card-header">
                                            <Link to={item.path}>
                                                <p>{item.date}</p>
                                                <h2>{item.header}</h2>
                                            </Link>
                                        </header>
                                    </div>
                                    <div className="card-img-container">
                                        <Link
                                            to={item.path}
                                            aria-label={item.header}
                                        >
                                            {item.img}
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="card-interior">
                                    <header className="card-header">
                                        <a href={item.href}>
                                            <p>{item.date}</p>
                                            <h2>{item.header}</h2>
                                        </a>
                                    </header>
                                    <div className="card-img-container">
                                        <a
                                            href={item.href}
                                            aria-label={item.header}
                                        >
                                            {item.img}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </article>
                    );
                })}
            </section>
        </>
    );
};

export default Cards;
