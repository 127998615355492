import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import logoImg from "../images/Shasta_Gymnastics.png";

export default function TeamPage() {
    return (
        <main>
            <Navbar />
            <InfoPage
                section1Header="Trampoline Wall"
                section1Text="Trampoline Wall Classes starting soon!"
                section1Image={logoImg}
                section1Alt="Logo"
                section2Header="Coming Soon!"
                section2Text="Come back soon to see more!"
                section2Image={logoImg}
                section2Alt="Logo"
            />
            <Footer />
        </main>
    );
}
