import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

export default function Missing() {
	const navigate = useNavigate();

	const goBack = () => navigate(-1);
	return (
		<section>
			<Navbar />
			<h1>Page Does Not Exist</h1>
			<div>
				<button onClick={goBack}>Take Me Back</button>
			</div>
			<Footer />
		</section>
	);
}
