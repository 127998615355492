import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Calendar from "@ericz1803/react-google-calendar";
import { useState } from "react";
import "../css/Calendar.css";

const API_KEY = "AIzaSyD2Q-4YzUyiWaaA7uUxcCYBQTJrH6hNcP0";
const calendars = [
  {
    //girls
    calendarId:
      "9e7cbf4b14aa286638c7438585d17e8a1c13681441b615907bc705f6a33ee1ad@group.calendar.google.com",
    color: "#57007c",
  },
  {
    //kinder
    calendarId:
      "fb1b1f9d05e810ed0c048ecc8eec580dc91091e7f732119e123e8673084e235e@group.calendar.google.com",
    color: "#007c34",
  },
  {
    //trampoline
    calendarId:
      "c4040471b87aa27c73ecbd95d3ad721bf9d8c96ce7f46076a4f3357ad624b932@group.calendar.google.com",
    color: "#cccc1b",
  },
  {
    //tumbling
    calendarId:
      "a710c73ad0331e33c3bdedad415ab7af74e4c9456ffe971c38ad98f19c624461@group.calendar.google.com",
    color: "#ff7434",
  },
  {
    //boys
    calendarId:
      "fdde3e94e7a5c1cd50fa3a68ff1c4c163eaedf72ab88d0fbb710246b10d8499e@group.calendar.google.com",
    color: "#00497c",
  },
  {
    //special events
    calendarId:
      "01a991626b3c207901291f69911ae0ae593de9e75b973917962cf81ca04074ab@group.calendar.google.com",
    color: "#00497c",
  },
  {
    //exhibition squad
    calendarId:
      "73117dec20e1a886bd55fed71cfe6304487ad44770008dd8b8615a7a69694e52@group.calendar.google.com",
    color: "#cc1b1b",
  },
  {
    calendarId: "en.usa#holiday@group.v.calendar.google.com",
    color: "#5ea822", //optional, specify color of calendar 2 events
  },
];

let styles = {
  //you can use object styles (no import required)
  calendar: {
    //make outer edge of calendar thicker
    borderWidth: "3px",
    zIndex: "0",
  },

  //you can also use emotion's string styles
  today: `
	  /* highlight today by making the text red and giving it a red border */
	  border-radius: 3px;
	  color: red;
	  border: 1px solid red;
	`,

  event: {
    border: "solid gray 1px",
  },

  eventText: {
    overflow: "hidden",
  },
};

const CalendarPage = () => {
  const [state, setState] = useState({
    languageSelection: "EN",
    key: 1,
  });

  const handleClickLang = (event) => {
    console.log(state.languageSelection);
    console.log(event.target.value);
    setState({
      languageSelection: event.target.value,
      key: (state.key += 1),
    });
  };

  return (
    <main className="calendar-container">
      <Navbar />
      <Calendar
        apiKey={API_KEY}
        calendars={calendars}
        styles={styles}
        showFooter={false}
        language={state.languageSelection}
        key={state.key}
      />
      <div className="calendar-footer">
        <p>
          All times shown in your current local time zone, may not reflect actual class times if you
          are out of state.
        </p>
        <div className="justify-right">
          <button value={"EN"} onClick={handleClickLang}>
            English
          </button>
          <button value={"ES"} onClick={handleClickLang}>
            Español
          </button>
          <button value={"FR"} onClick={handleClickLang}>
            Français
          </button>
          <button value={"DE"} onClick={handleClickLang}>
            Deutsch
          </button>
          <button value={"PT"} onClick={handleClickLang}>
            Português
          </button>
          <button value={"PL"} onClick={handleClickLang}>
            Polish
          </button>
          <button value={"SL"} onClick={handleClickLang}>
            Slovak
          </button>
        </div>
      </div>
      <h1 className="center">
        Give us a call at{" "}
        <a className="tel" href="tel:5302299194">
          (530)-229-9194
        </a>{" "}
        for more info about class availability, to schedule a birthday party, or to enroll your
        child, office hours are 12:00-7:00pm Monday-Friday
      </h1>
      <Footer />
    </main>
  );
};

export default CalendarPage;
