import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Cards from "../components/Cards";

import { CardData } from "../components/ComponentData/CardData";
import * as TbIcons from "react-icons/tb";
import "../css/Homepage.css";

export default function Homepage() {
  return (
    <main>
      <Navbar />
      <section className="homepage-section-containter">
        <div className="rectangle-1"></div>
        <div className="rectangle-2"></div>
        <div className="rectangle-3"></div>
        <div className="rectangle-4"></div>
        <div className="h1-wrapper">
          <div className="header-text-wrap">
            <div className="header-text">
              <h1 className="homepage-h1">
                <div className="title">
                  <span>W</span>
                  <span>E</span>
                  <span>L</span>
                  <span>C</span>
                  <span>O</span>
                  <span>M</span>
                  <span>E </span>
                </div>
                <div className="title">
                  <span>T</span>
                  <span>O </span>
                </div>
                <div className="title">
                  <span>S</span>
                  <span>H</span>
                  <span>A</span>
                  <span>S</span>
                  <span>T</span>
                  <span>A </span>
                </div>
                <div className="title">
                  <span>G</span>
                  <span>Y</span>
                  <span>M</span>
                  <span>N</span>
                  <span>A</span>
                  <span>S</span>
                  <span>T</span>
                  <span>I</span>
                  <span>C</span>
                  <span>S </span>
                </div>
                <div className="title">
                  <span>A</span>
                  <span>C</span>
                  <span>A</span>
                  <span>D</span>
                  <span>E</span>
                  <span>M</span>
                  <span>Y</span>
                </div>
              </h1>
              <p className="homepage-info-p2 text-center" style={{ fontWeight: "bold" }}>
                The premiere gym in Redding, CA for Trampoline, Tumbling, Gymnastics, and
                Preschool/Kinder Gym!
              </p>
            </div>
          </div>
        </div>
        <div className="homepage-info-container">
          <p
            className="homepage-info-p text-blue text-center"
            style={{
              fontWeight: "600",
            }}
          >
            For more info, to sign up for classes, scheduling birthday parties, or scheduling
            make-ups come on in or give us a call at:&nbsp;
            <a className="homepage-info-a" href="tel:5302299194">
              (530)-229-9194
            </a>
          </p>
          <p
            className="homepage-info-p text-center"
            style={{
              fontWeight: "600",
            }}
          >
            Kinder Gym, Trampoline, Gymnastics, Tumbling, Trampoline Wall, Birthdays, and Member
            Open Gym we have it all! Proudly serving Redding, Anderson, Palo Cedro, Cottonwood, and
            Shasta Lake.
          </p>
          <div className="homepage-times text-center">
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Our office hours are:
            </p>
            <p className="text-red homepage-info-p">Monday: 12:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Tuesday: 12:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Wednesday: 12:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Thursday: 12:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Friday: 12:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Sat-Sun: Closed</p>
          </div>
          <div className="homepage-times text-center">
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Our business hours are:
            </p>
            <p className="text-red homepage-info-p">Monday: 3:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Tuesday: 2:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Wednesday: 3:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Thursday: 2:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Friday: 3:00pm - 8:00pm</p>
            <p className="text-red homepage-info-p">Sat-Sun: Birthday Parties by Request</p>
          </div>
          <div className="homepage-times text-center">
            <p>We are located at:</p>
            <a
              href="https://goo.gl/maps/DVMGa1QsRyH5BLgNA"
              style={{
                textDecoration: "none",
                color: "inherit",
                fontWeight: "bold",
              }}
              className="homepage-info-p"
            >
              2629 Bechelli Ln, Redding, CA 96002
            </a>
          </div>
          <div>
            <p className="homepage-info-p text-center">SAFETY INFO FOR PARENTS:</p>
          </div>
          <div
            style={{
              width: "max-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <a href="https://usagym.org/safety/parents/" className="parent-info-link">
              <button className="parent-info-button">Click Here</button>
            </a>
          </div>
        </div>
      </section>
      <h2 className="photo-reel-h2 text-center">
        <span>Find out more</span>
        <TbIcons.TbClick />
      </h2>
      <Cards Data={CardData} />
      <Footer />
    </main>
  );
}
