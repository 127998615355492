import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import InfoPage from "../components/Infopage.jsx";

import * as Index from "../components/images/index.js";

export default function FacilityPage() {
    return (
        <main>
            <Navbar />
            <InfoPage
                section1Header="High Quality Equipment and Facility"
                section1Text={`We at Shasta Gymnastics Academy (SGA),  know the importance of maintaining a clean, safe and up-to-date training facility that allows our athletes to thrive. We are proud to offer top-quality gymnastics and trampoline equipment and facilities for athletes of all ages. 
				Our state-of-the-art facility includes two Olympic-grade competitive trampolines, a professional training trampoline, and a full-size fiberglass rod floor. In addition, we have all the necessary equipment for competitive Women's Artistic Gymnastics. 
        `}
                section1Image={Index.RodFloorPerspective}
                section1Alt="Rod Floor"
                section2Header="Safety and Skill"
                section2Text={`Our coaches are dedicated to providing a safe and clean environment for our athletes to thrive and reach their full potential. We are constantly updating and maintaining our equipment to ensure it meets the highest safety standards. 
				So whether you are a beginner or an experienced gymnast, you can trust that you will be training on the best equipment available. As well as teaching proper falling techniques (believe it or not, there is a best way to fall!) to ensure the safety of our athletes.`}
                section2Image={Index.DoubleMini1}
                section2Alt="Double Mini Trampoline"
            />
            <Footer />
        </main>
    );
}
